<template>
  <div style="background: #ebedf0;height: 100%;">
    <van-nav-bar title="套餐消费详情" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <van-cell-group class="hed" style="padding-top:46px;background: #ebedf0;">
      <van-cell style="border-radius: 4px 4px 0 0;">
        <van-row type="flex" justify="space-between">
          <van-col span="24" style="font-weight:bold;">套餐名称：{{info.comboName}}</van-col>
        </van-row>
      </van-cell>
      <van-cell>
        <van-row type="flex" justify="space-between">
          <van-col span="12" class="content">
            <span style="font-weight:bold;">客户：</span>
            <span style="color: #666;">{{info.customerName}}</span>
          </van-col>
          <van-col span="12" class="content" style="text-align:right;">
            <span style="font-weight:bold;">手机号：</span>
            <span style="color: #666666;">{{info.phone}}</span>
          </van-col>
        </van-row>
      </van-cell>
      <van-cell style="border-radius: 0px 0px 4px 4px;">
        <van-row type="flex" justify="space-between">
          <van-col span="24" class="content">
            <span style="font-weight:bold;">车牌：</span>
            <div class="pai">{{info.carNo}}</div>
          </van-col>
        </van-row>
      </van-cell>
    </van-cell-group>
    <div class="mian">
      <van-row type="flex" justify="space-between" class="shijian">
        <div class="mian_a">
          <van-col span="12" class="content">办理时间：{{info.startDate.substring(0,10)}}</van-col>
          <van-col
            span="12"
            class="content"
            style="text-align:right;"
          >失效时间：{{info.endDate.substring(0,10)}}</van-col>
        </div>
      </van-row>
      <van-steps direction="vertical" :active="0" active-color="#DF3D48">
        <van-step v-for="(v, index) in info.recordVOList" :key="index">
          <div class="shu_a">{{v.createdDate.substring(0,10)}}</div>
          <p class="shu_b">{{v.billNumber}}</p>
          <p class="shu_c">{{v.itemName}} {{v.count}}次</p>
        </van-step>
      </van-steps>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";

export default {
  data() {
    return {
      info: {}
    };
  },
  methods: {
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .findRecordByComboCustomerId({ comboCustomerId: this.$route.params.id })
        .then(e => {
          loading.clear();
          this.info = e.data;
        })
        .catch(() => loading.clear());
    },
    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.content {
  font-size: 14px;
}
.cent div {
  text-align: center;
}
.hed {
  margin: 10px 10px;
  border-radius: 4px;
}
.mian {
  background: #fff;
  border-radius: 4px;
  margin: 0 12px;
}
.mian_a {
  margin: 7px 12px;
  padding: 6px 8px;
  background: #f5f5f5;
  border-radius: 4px;
  color: #666666;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  width: 100%;
}
.shu_a {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666666;
  letter-spacing: 0;
}
.shu_b {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  margin-top: 5px;
}
.shu_c {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #666666;
  letter-spacing: 0;
  margin-bottom: 5px;
}
.pai {
  display: inline-block;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  height: 26px;
  width: 72px;
  background: url(../../assets/img/chepaibj.png) no-repeat;
  background-size: 100% 100%;
}
</style>
